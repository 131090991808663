<template>
  <div id="app">
    <video id="myVideo" controls class="video"></video>
  </div>
</template>

<script setup lang="ts">
import Hls from "hls.js";
import {onMounted} from "vue";

onMounted(() => {
    var url = decodeURI(window.location.search);
    var obj = {};
    if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      var strs = str.split("&");
      for (var i = 0; i < strs.length; i++) {
        obj[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
      }
    }
    const myVideo = document.getElementById('myVideo');
    const streamUrl = obj['url'];
    if (Hls.isSupported()) {
      const hls = new Hls();
      // 加载并播放视频
      hls.attachMedia(myVideo);
      hls.loadSource(streamUrl);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        console.log('Media video added successfully!');
        // myVideo.play();
      });
    } else if (myVideo.canPlayType('application/vnd.apple.mpegurl')) {
      // 对于支持原生 HLS 的设备 (如 iOS Safari)
      myVideo.src = streamUrl;
      myVideo.addEventListener('loadedmetadata', function () {
        myVideo.play();
      });
    }
  }
)
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.video {
  width: 100%;
  height: 100vh;
}
</style>
